// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Recomendation {
  margin: 46px 0;
}
.Recomendation .container {
  padding: 20px 0;
  background-color: #1f1f55;
  color: #ffffff;
}
.Recomendation .SwiperArrow path {
  fill: #ffffff;
}
.Recomendation-Title {
  padding: 50px 0;
  font-size: 32px;
  text-align: center;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Recomendation/Recomendation.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;AAEE;EACE,eAAA;EACA,yBCHc;EDId,cCDiB;ADCrB;AAGI;EACE,aCLe;ADIrB;AAIE;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,cCZiB;ADUrB","sourcesContent":["@import '../../styles/variables.scss';\n\n.Recomendation {\n  margin: 46px 0;\n  .container {\n    padding: 20px 0;\n    background-color: $primary-dark-bg;\n    color: $primary-light-text;\n  }\n  .SwiperArrow {\n    path {\n      fill: $primary-light-text;\n    }\n  }\n  &-Title {\n    padding: 50px 0;\n    font-size: 32px;\n    text-align: center;\n    color: $primary-light-text;\n  }\n}\n","// COLORS\n$primary-dark-text: #282828;\n$primary-dark-title: #1f1f55;\n$primary-dark-bg: #1f1f55;\n\n$primary-light-title: #ffffff;\n$primary-light-text: #ffffff;\n$primary-light-bg: #ffffff;\n\n$secondary-light-title: #d9d9d9;\n$secondary-light-text: #d9d9d9;\n$secondary-dark-title: #684add;\n\n$secondary-bg: #42dbbf;\n$secondary-light-bg: #d9d9d9;\n\n$primary-dark-hover: #28282817;\n$primary-light-hover: #d8d8fe17;\n\n//HEW COLOR\n$main-blue: #1f1f55;\n$main-green: #42dbbf;\n$main-purple: #684add;\n$main-yellow: #bdea09;\n$main-white: #ffffff;\n\n$shaded-mid-blue: #575797;\n$shaded-mid-light-blue: #8181da;\n$shaded-light-blue: #d8d8fe;\n$shaded-mid-light-green: #8df4e2;\n$shaded-light-green: #cefff6;\n\n$text-color-light: #e5e5e6;\n$text-color-middle: #808099;\n$text-color-dark: #282828;\n\n$allert-color-red: #eb5757;\n$stroke-color: #f2994a;\n$stroke-color-light: #d9d9d9;\n\n$hover-color-dark: #28282817;\n$hover-color-light: #d8d8fe17;\n\n$box-shadow-color: #00000040;\n\n//SIZES\n$main-border-radius: 6px;\n\n// ANIMATION\n$main-transition: ease-in-out 200ms;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
